import { j as i } from "./jsx-runtime-BcAkpsdy.js";
import $ from "styled-components";
import f from "clsx";
import "./Typography-BlwIOT6S.js";
import { TypographyCSS as t } from "./components/Typography/styles.es.js";
const s = {
  xxs: 64,
  xs: 160,
  sm: 200,
  md: 240,
  lg: 280
}, g = {
  xxs: 6,
  xs: 16,
  sm: 20,
  md: 24,
  lg: 28
}, u = $.div`
  justify-self: center;
  align-self: center;
  flex-shrink: 0;
  
  display: grid;
  gap: 6px;
  
  & > * {
    grid-column: 1;
    grid-row: 1;
    justify-self: center;
    align-self: center;
  }
  
  header {
    color: ${(e) => e.theme.palette.grays[600].hex};
  }

  main {
    color: ${(e) => e.theme.palette.grays[700].hex};
  }
  
  &.size-xxs {
    width: ${() => `${s.xxs}px`};
    height: ${() => `${s.xxs}px`};
    main {
      
      ${t.text.sm}
      ${t.medium}
    }
    footer {
      ${t.text.xs}
      ${t.medium}
      grid-row: 2;
    }
  }

  &.size-xs {
    width: ${() => `${s.xs}px`};
    height: ${() => `${s.xs}px`};
    header {
      ${t.text.xs}
      ${t.medium}
    }
    main {
      ${t.display.xs}
      ${t.medium}
    }
  }
  
  &.size-sm {
    width: ${() => `${s.sm}px`};
    height: ${() => `${s.sm}px`};
    header {
      ${t.text.xs}
      ${t.medium}
    }
    main {
      ${t.display.sm}
      ${t.medium}
    }
  }
  
  &.size-md {
    width: ${() => `${s.md}px`};
    height: ${() => `${s.md}px`};
    header {
      ${t.text.sm}
      ${t.medium}
    }
    main {
      ${t.display.md}
      ${t.medium}
    }
  }
  
  &.size-lg {
    width: ${() => `${s.lg}px`};
    height: ${() => `${s.lg}px`};
    header {
      ${t.text.sm}
      ${t.medium}
    }
    main {
      ${t.display.lg}
      ${t.medium}
    }
  }
  
  article {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .fixed {
    stroke: ${(e) => e.theme.palette.grays[200].hex};
  }
  
  .dynamic {
    stroke: ${(e) => e.theme.palette.primary[600].hex};
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  
  &.indeterminate {
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    @-webkit-keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    
    .dynamic {
      transition: all 0.5s ease-in;
      animation-name: rotate;
      animation-duration: 0.5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
`, b = (e) => {
  const {
    shape: o = "full",
    size: n = "xs",
    className: x
  } = e, c = f(
    `shape-${o}`,
    `size-${n}`,
    {
      indeterminate: e.indeterminate
    },
    x
  ), m = s[n], l = g[n], r = m / 2, d = r - l / 2, a = d * 2 * Math.PI, h = e.indeterminate ? 2 * a / 3 : a - e.progress * a / 100;
  return /* @__PURE__ */ i.jsxs(u, { className: c, children: [
    /* @__PURE__ */ i.jsxs("svg", { width: m, height: m, children: [
      /* @__PURE__ */ i.jsx(
        "circle",
        {
          r: d,
          cx: r,
          cy: r,
          strokeWidth: l,
          fill: "transparent",
          className: "fixed"
        }
      ),
      /* @__PURE__ */ i.jsx(
        "circle",
        {
          r: d,
          cx: r,
          cy: r,
          strokeWidth: l,
          strokeDasharray: `${a} ${a}`,
          strokeDashoffset: `${h}`,
          strokeLinecap: "round",
          fill: "transparent",
          className: "dynamic"
        }
      )
    ] }),
    !e.indeterminate && e.withLabel && e.size !== "xxs" && /* @__PURE__ */ i.jsxs("article", { children: [
      e.label && /* @__PURE__ */ i.jsx("header", { children: e.label }),
      /* @__PURE__ */ i.jsx("main", { children: `${e.progress}%` })
    ] }),
    !e.indeterminate && e.withLabel && e.size === "xxs" && /* @__PURE__ */ i.jsxs(i.Fragment, { children: [
      /* @__PURE__ */ i.jsx("main", { children: `${e.progress}%` }),
      e.label && /* @__PURE__ */ i.jsx("footer", { children: e.label })
    ] }),
    e.indeterminate && e.children
  ] });
};
export {
  b as P
};
