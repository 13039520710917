import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  axios,
  LoadingWidget,
  PasswordResetCommencedWidget,
  PasswordResetCommenceWidget,
  PasswordResetCommittedWidget,
  PasswordResetCommitWidget,
  SomethingWentWrongWidget,
  usePasswordReset,
} from "@spotswap/shared";
import { useAppSelector } from "src/redux/hooks";
import { links } from "src/config";

const Root = styled.div`
  background-color: ${(props) => props.theme.palette.white.hex};

  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: stretch;

  > main {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: stretch;

    > * {
      flex: 1;
      margin: 16px;
    }
  }
`;

type State = "loading" | "commence" | "commenced" | "commit" | "committed" | "error";

const PasswordResetPage = () => {
  const {
    config,
  } = useAppSelector((x) => x.environment);

  if (!config) {
    throw Error("[PasswordResetPage] A component was used prior the config load");
  }

  const {
    auth: {
      clientID,
    },
  } = config;

  const {
    isCommenced,
    ticket,
    onCommence,
    onVerify,
    onCommit,
  } = usePasswordReset({
    axios,
    clientID,
  });

  const [ state, setState ] = useState<State>(ticket ? "loading" : "commence");
  const [ email, setEmail ] = useState<string>("");
  const [ hint, setHint ] = useState<string>("");

  useEffect(() => {
    if (ticket) {
      onVerify(ticket)
        .then(() => {
          setState("commit");
        }, () => {
          setState("error");
        });
    }
  }, [ticket]);

  useEffect(() => {
    if (isCommenced) {
      setState("commenced");
    }
  }, [isCommenced]);

  const doCommence = async (username: string): Promise<void> => {
    try {
      await onCommence(username);
      setEmail(username);
    } catch (e) {
      console.error(e);
      setState("error");
    }
  };

  const doCommit = async (password: string): Promise<void> => {
    try {
      if (ticket) {
        const response = await onCommit(ticket, password);
        if (response) {
          setHint(response.message);
        } else {
          setState("committed");
        }
      } else {
        throw Error("[PasswordResetPage] A new password request is submitted, but current ticket is missing");
      }
    } catch (e) {
      console.error(e);
      setState("error");
    }
  };

  return (
    <Root>
      <main>
        {state === "loading" && (
          <LoadingWidget />
        )}
        {state === "commence" && (
          <PasswordResetCommenceWidget
            backUri={links.login}
            onCommence={doCommence}
          />
        )}
        {state === "commenced" && (
          <PasswordResetCommencedWidget
            backUri={links.login}
            email={email}
          />
        )}
        {state === "commit" && (
          <PasswordResetCommitWidget
            error={hint}
            backUri={links.login}
            onCommit={doCommit}
          />
        )}
        {state === "committed" && (
          <PasswordResetCommittedWidget
            loginUri={links.login}
          />
        )}
        {state === "error" && (
          <SomethingWentWrongWidget />
        )}
      </main>
    </Root>
  );
};

export default PasswordResetPage;
