import { j as a } from "./jsx-runtime-BcAkpsdy.js";
import n from "styled-components";
import { I as d } from "./Icon-DksUnNYD.js";
import "./Typography-BlwIOT6S.js";
import { TypographyCSS as i } from "./components/Typography/styles.es.js";
import { i as p } from "./pages-CKVAUZ3g.js";
import { AvatarCSS as e } from "./components/Avatar/styles.es.js";
const o = n.div`
  border-radius: 50%;
  
  overflow: hidden;
  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-width: 1px;
  border-style: solid;
  border-color: ${(t) => t.theme.palette.grays[200].hex};
  background-color: ${(t) => t.theme.palette.grays[100].hex};
  
  &.variant-initials {
    color: ${(t) => t.theme.palette.grays[600].hex};
  }
  
  &.variant-icon {
    color: ${(t) => t.theme.palette.grays[900].hex};
    
    & > img {
      
      
      &.size-sm {
        ${e.sm}
        ${i.text.sm}
        ${i.bold}
      }
    
      &.size-md {
        ${e.md}
        ${i.text.md}
        ${i.bold}
      }
    
      &.size-lg {
        ${e.lg}
        ${i.text.lg}
        ${i.bold}
      }
    
      &.size-xl {
        ${e.xl}
        ${i.text.xl}
        ${i.bold}
      }
    
      &.size-xxl {
        ${e.xxl}
        ${i.display.xs}
        ${i.bold}
      }
    }
  }
  
  &.variant-image > img {
    object-fit: cover;
    max-width: 100%;
    min-height: 100%; 
  }

  &.size-xs {
    ${e.xs}
    ${i.text.xs}
    ${i.bold}
    
    &.variant-icon > img {
      width: 16px;
      height: 16px;
    }
  }
  
  &.size-sm {
    ${e.sm}
    ${i.text.sm}
    ${i.bold}

    &.variant-icon > img {
      width: 20px;
      height: 20px;
    }
  }

  &.size-md {
    ${e.md}
    ${i.text.md}
    ${i.bold}

    &.variant-icon > img {
      width: 24px;
      height: 24px;
    }
  }

  &.size-lg {
    ${e.lg}
    ${i.text.lg}
    ${i.bold}

    &.variant-icon > img {
      width: 28px;
      height: 28px;
    }
  }

  &.size-xl {
    ${e.xl}
    ${i.text.xl}
    ${i.bold}

    &.variant-icon > img {
      width: 32px;
      height: 32px;
    }
  }

  &.size-xxl {
    ${e.xxl}
    ${i.display.xs}
    ${i.bold}

    &.variant-icon > img {
      width: 32px;
      height: 32px;
    }
  }

  &:focus {
    outline: 4px solid ${(t) => t.theme.palette.grays[100].hex};
  }
`, y = (t) => {
  const {
    size: r = "sm",
    ...s
  } = t, x = (m) => m.replace(/\s+/, " ").split(" ").slice(0, 2).map((l) => l && l[0].toUpperCase()).join("");
  switch (s.variant) {
    case "icon":
      return /* @__PURE__ */ a.jsx(o, { className: `variant-icon size-${r}`, children: /* @__PURE__ */ a.jsx(d, { variant: s.icon }) });
    case "initials":
      return /* @__PURE__ */ a.jsx(o, { className: `variant-initials size-${r}`, children: x(s.displayName) });
    case "image":
      return /* @__PURE__ */ a.jsx(o, { className: `variant-image size-${r}`, children: /* @__PURE__ */ a.jsx("img", { src: s.src, alt: s.alt }) });
    default:
      p(s);
  }
};
export {
  y as A
};
