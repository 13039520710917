import { j as t } from "./jsx-runtime-BcAkpsdy.js";
import { useState as y } from "react";
import u from "styled-components";
import { I as d } from "./Icon-DksUnNYD.js";
import { L as g } from "./Logo-Dr9y5hZ1.js";
import { u as b } from "./useDeviceQuery-BNUlEe-Z.js";
import { i as $ } from "./pages-CKVAUZ3g.js";
import f from "clsx";
const m = u.nav`
  &.closed .popup {
    display: none;
  }
  
  &.open {
    overflow: hidden;
  }
    
  header {
    width: 100%;
    height: 64px;

    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 12px 8px 12px 16px;
    box-sizing: border-box;

    > img {
      flex: 0;
      height: 32px;
    }

    > button {
      margin-left: auto;
      width: 40px;
      height: 40px;
      padding: 8px;

      border-radius: 8px;
      border-width: 0;
      border-style: none;
    }
  }
  
  main {
    flex: 1;

    display: flex;
    flex-flow: column;
    
    overflow-y: auto;

    > ul {
      flex: 1;
      margin: 0;
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      align-self: stretch;
      gap: 4px;
    }
  }
  
  .popup {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    
    display: flex;
    flex-direction: column;
  }
  

  &.theme-admin, &.theme-enterprise {
    header {
      color: ${(e) => e.theme.palette.grays[950].contrastText};
      background-color: ${(e) => e.theme.palette.grays[950].hex};
      
      > button {
        color: ${(e) => e.theme.palette.grays[950].contrastText};
        background-color: ${(e) => e.theme.palette.grays[950].hex};

        &:hover {
          color: ${(e) => e.theme.palette.grays[800].contrastText};
          background-color: ${(e) => e.theme.palette.grays[800].hex};
        }

        &:focus {
          outline: 4px solid #667085;
        }
      }
    }
    
    main {
      padding-top: 10px;
        
      color: ${(e) => e.theme.palette.grays[950].contrastText};
      background-color: ${(e) => e.theme.palette.grays[950].hex};
        
      border-top: 1px solid ${(e) => e.theme.palette.grays[600].hex};
        
      > header {
        border-top: 1px solid ${(e) => e.theme.palette.grays[600].hex};
      }
        
      > ul {
        > li {
          > a {
            color: ${(e) => e.theme.palette.grays[100].hex};
            background-color: ${(e) => e.theme.palette.grays[950].hex};

            &:hover {
              background-color: ${(e) => e.theme.palette.grays[800].hex};
            }

            &.active {
              background-color: ${(e) => e.theme.palette.grays[800].hex};
            }
          }
        }

        > li:last-child {
          border-top: 1px solid ${(e) => e.theme.palette.grays[600].hex};
        }
      }
    }
  }

  &.theme-global {
    header {
      color: ${(e) => e.theme.palette.grays[950].contrastText};
      background-color: ${(e) => e.theme.palette.white.hex};
      
      > button {
        color: ${(e) => e.theme.palette.grays[500].hex};
        background-color: ${(e) => e.theme.palette.white.hex};

        &:hover {
          color: ${(e) => e.theme.palette.grays[900].hex};
          background-color: ${(e) => e.theme.palette.grays[100].hex};
        }

        &:focus {
          outline: 4px solid ${(e) => e.theme.palette.grays[200].hex};
        }
      }
    }
    
    main {
      padding-top: 10px;
        
      color: ${(e) => e.theme.palette.grays[950].contrastText};
      background-color: ${(e) => e.theme.palette.white.hex};
        
      border-top: 1px solid ${(e) => e.theme.palette.grays[200].hex};
      
      > ul {
        > li {
          > a {
          color: ${(e) => e.theme.palette.grays[700].hex};
          background-color: ${(e) => e.theme.palette.white};
          
          > svg {
            background-color: ${(e) => e.theme.palette.grays[500].hex};
          }

          &:hover {
            color: ${(e) => e.theme.palette.grays[900].hex};
            background-color: ${(e) => e.theme.palette.grays[100].hex};
            
            > svg {
              background-color: ${(e) => e.theme.palette.grays[600].hex};
            }
          }

          &.active {
            color: ${(e) => e.theme.palette.grays[900].hex};
            background-color: ${(e) => e.theme.palette.grays[100].hex};
            
            > svg {
              background-color: ${(e) => e.theme.palette.grays[600].hex};
            }
          }
        }
        }
        > li:last-child {
          border-top: 1px solid ${(e) => e.theme.palette.grays[200].hex};
        }
      }
    }
  }
`, k = u.nav`
  width: 280px;
  height: 100%;
  
  display: flex;
  flex-direction: column;
  align-items: stretch;
  
  
  > img {
    padding: 32px 20px 24px 20px;
    align-self: flex-start;
  }
  
  > ul {
    flex: 1;
    margin: 0;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 4px;
  }

  &.theme-admin, &.theme-enterprise {
    color: ${(e) => e.theme.palette.grays[950].contrastText};
    background-color: ${(e) => e.theme.palette.grays[950].hex};
    
    > ul {
      > li {
        > a {
          color: ${(e) => e.theme.palette.grays[100].hex};
          background-color: ${(e) => e.theme.palette.grays[950].hex};

          &:hover {
            background-color: ${(e) => e.theme.palette.grays[800].hex};
          }

          &.active {
            background-color: ${(e) => e.theme.palette.grays[800].hex};
          }
        }
      }

      > li:last-child {
        align-items: center;
        border-top: 1px solid ${(e) => e.theme.palette.grays[600].hex};
      }
    }
  }

  &.theme-global {
    color: ${(e) => e.theme.palette.grays[950].contrastText};
    background-color: ${(e) => e.theme.palette.grays[950].hex};
    
    > ul {
      > li {
        > a {
          color: ${(e) => e.theme.palette.grays[700].hex};
          background-color: ${(e) => e.theme.palette.white};
          
          > svg {
            background-color: ${(e) => e.theme.palette.grays[500].hex};
          }

          &:hover {
            color: ${(e) => e.theme.palette.grays[900].hex};
            background-color: ${(e) => e.theme.palette.grays[100].hex};
            
            > svg {
              background-color: ${(e) => e.theme.palette.grays[600].hex};
            }
          }

          &.active {
            color: ${(e) => e.theme.palette.grays[900].hex};
            background-color: ${(e) => e.theme.palette.grays[100].hex};
            
            > svg {
              background-color: ${(e) => e.theme.palette.grays[600].hex};
            }
          }
        }
      }
      
      > li:last-child {
        border-top: 1px solid ${(e) => e.theme.palette.grays[200].hex};
      }
    }
  }
`, K = (e) => {
  const {
    children: a,
    theme: r,
    ...l
  } = e, i = b(), [s, h] = y(!1), o = /* @__PURE__ */ t.jsxs("header", { children: [
    /* @__PURE__ */ t.jsx(g, { variant: r === "global" ? "spotswap-black" : "spotswap-white" }),
    /* @__PURE__ */ t.jsxs("button", { onClick: () => h((p) => !p), children: [
      !s && /* @__PURE__ */ t.jsx(d, { variant: "menu" }),
      s && /* @__PURE__ */ t.jsx(d, { variant: "close" })
    ] })
  ] }), n = () => {
    h(!1);
  }, x = (p) => {
    p.key === "Enter" && h(!1);
  }, c = f(
    `theme-${r}`,
    s ? "open" : "closed"
  );
  switch (i) {
    case "mobile":
      return /* @__PURE__ */ t.jsxs(m, { ...l, className: c, children: [
        o,
        /* @__PURE__ */ t.jsxs("div", { className: "popup", children: [
          o,
          /* @__PURE__ */ t.jsx(
            "main",
            {
              role: "presentation",
              onClick: n,
              onKeyDown: x,
              children: /* @__PURE__ */ t.jsx("ul", { children: a })
            }
          )
        ] })
      ] });
    case "tablet":
      return /* @__PURE__ */ t.jsxs(m, { ...l, className: c, children: [
        o,
        /* @__PURE__ */ t.jsxs("div", { className: "popup", children: [
          o,
          /* @__PURE__ */ t.jsx(
            "main",
            {
              role: "presentation",
              onClick: n,
              onKeyDown: x,
              children: /* @__PURE__ */ t.jsx("ul", { children: a })
            }
          )
        ] })
      ] });
    case "desktop":
      return /* @__PURE__ */ t.jsxs(k, { ...l, className: c, children: [
        /* @__PURE__ */ t.jsx(g, { variant: r === "global" ? "spotswap-black" : "spotswap-white" }),
        /* @__PURE__ */ t.jsx("ul", { children: a })
      ] });
    case "printer":
    case void 0:
      return null;
    default:
      $(i);
  }
};
export {
  K as S
};
