import styled from "styled-components";
import SidebarItem from "@spotswap/shared/SidebarItem";
import { links } from "src/config";
import {useAppSelector} from "../../redux/hooks.ts";

const Root = styled.li`
  list-style: none;
  
  &.gap {
    flex: 1;
  }
`;

const SidebarMenu = () => {
  const access = useAppSelector((x) => x.auth.access);

  if (!access) {
    throw Error("[SidebarMenu] A component was used prior the auth context load");
  }

  return (
    <>
      <Root>
        <SidebarItem
          icon="home"
          text="Home"
          to={links.home}
        />
      </Root>
      <Root>
        <SidebarItem
          icon="rows"
          text="Waitlists"
          to={links.waitlists}
        />
      </Root>
      <Root>
        <SidebarItem
          icon="currency-dollar-circle"
          text="Trades"
          to={links.trades.root}
        />
      </Root>
      <Root>
        {access === "admin" && (
          <SidebarItem
            icon="settings"
            text="Settings"
            to={links.settings.root}
          />
        )}
      </Root>
      <Root className="gap" />
    </>
  );
};

export default SidebarMenu;
