import { css as x } from "styled-components";
import "../../Typography-BlwIOT6S.js";
import { TypographyCSS as p } from "../Typography/styles.es.js";
const g = {
  sm: x`${p.text.sm} ${p.bold} height: 36px; padding: 8px 14px`,
  md: x`${p.text.sm} ${p.bold} height: 40px; padding: 10px 16px`,
  lg: x`${p.text.md} ${p.bold} height: 44px; padding: 10px 18px`,
  xl: x`${p.text.md} ${p.bold} height: 48px; padding: 12px 20px`,
  xxl: x`${p.text.lg} ${p.bold} height: 60px; padding: 16px 28px`
};
export {
  g as ButtonCSS
};
