import { css as h } from "styled-components";
const t = {
  xs: h`width: 24px; height: 24px;`,
  sm: h`width: 32px; height: 32px;`,
  md: h`width: 40px; height: 40px;`,
  lg: h`width: 48px; height: 48px;`,
  xl: h`width: 56px; height: 56px;`,
  xxl: h`width: 64px; height: 64px;`
};
export {
  t as AvatarCSS
};
