import { useLogout } from "@spotswap/shared";
import { authLogout } from "src/redux/auth";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { useEffect } from "react";

const LogoutPage = () => {

  const {
    config,
  } = useAppSelector((x) => x.environment);

  const dispatch = useAppDispatch();

  const {
    onLogout,
  } = useLogout({
    config,
    doLogout: () => dispatch(authLogout()),
  });

  useEffect(() => {
    onLogout();
  }, []);

  return null;
};

export default LogoutPage;
