import { css as e } from "styled-components";
const i = {
  text: {
    xs: e`font-size: 12px; line-height: 18px;`,
    sm: e`font-size: 14px; line-height: 20px;`,
    md: e`font-size: 16px; line-height: 24px;`,
    lg: e`font-size: 18px; line-height: 24px;`,
    xl: e`font-size: 20px; line-height: 30px;`
  },
  display: {
    xs: e`letter-spacing: -0.02em; font-size: 24px; line-height: 32px;`,
    sm: e`letter-spacing: -0.02em; font-size: 30px; line-height: 38px;`,
    md: e`letter-spacing: -0.02em; font-size: 36px; line-height: 44px;`,
    lg: e`letter-spacing: -0.02em; font-size: 48px; line-height: 60px;`,
    xl: e`letter-spacing: -0.02em; font-size: 60px; line-height: 72px;`,
    xxl: e`letter-spacing: -0.02em; font-size: 72px; line-height: 90px;`
  },
  regular: e`font-weight: 400;`,
  medium: e`font-weight: 500;`,
  bold: e`font-weight: 700;`,
  black: e`font-weight: 900;`
};
export {
  i as TypographyCSS
};
