import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { removeNotification } from "../../redux/notifications";
import { useEffect } from "react";
import { Notification } from "@spotswap/shared/Notification";
import {DeviceQueries} from "@spotswap/shared";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  position: absolute;
  right: 24px;
  bottom: 24px;
  
  @media ${DeviceQueries.mobile} {
    right: 54px;
    bottom: 16px;
  }
    
    z-index: 9999;
`;

type Props = {
  count?: number;
  timer?: number;
  className?: string;
};
export const NotificationsWidget = (props: Props) => {
  const {
    count = 3,
    timer = 5000,
    className,
  } = props;

  const notifications = useAppSelector((x) => x?.notifications?.notifications);
  const dispatch = useAppDispatch();

  const filteredNotifications = notifications.length > count ? notifications.slice(-count) : notifications;

  useEffect(() => {
    if (notifications.length === 0) {
      return;
    }

    const interval = setInterval(() => {
      dispatch(removeNotification(filteredNotifications[filteredNotifications.length - 1].id));
    }, timer);


    return () => {
      clearInterval(interval);
    };

  }, [notifications]);

  return (
    <Root className={className}>
      {filteredNotifications.map((item, index) => (
        <Notification
          key={`notification-${index}`}
          {...item}
          className="invitation-alert"
          onClose={() => dispatch(removeNotification(item.id))}
        />
      ))}
    </Root>
  );
};

export default NotificationsWidget;
