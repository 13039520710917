import { j as l } from "./jsx-runtime-BcAkpsdy.js";
import n from "styled-components";
const a = n.div`
  font-family: inherit;
  
  display: flex;
  flex-direction: column;
  align-items: stretch;
  
  gap: 6px;
`, c = (t) => {
  const {
    label: e,
    element: s,
    supportingText: o,
    className: i,
    ...r
  } = t;
  return /* @__PURE__ */ l.jsxs(a, { ...r, className: i, children: [
    e,
    s,
    o
  ] });
};
export {
  c as F
};
