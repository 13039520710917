import {LoaderFunction, redirect} from "react-router-dom";
import {store} from "src/redux/store";
import {authInit} from "src/redux/auth";
import {requestClient} from "src/environment";
import {PartnerContextQueryNode} from "./PartnerContext";
import {PartnerStatus} from "../../api/graphql.ts";

export const partnerContextLoader = (wrapped: LoaderFunction): LoaderFunction => {
  return async (args) => {
    const {
      auth,
    } = store.getState();

    console.debug("[partnerContextLoader] Checking if partner context is present");

    if (!auth.user || !auth.enterprise) {
      console.debug("[partnerContextLoader] Partner context is not present, attempting to reacquire");

      const response = await requestClient.request({
        document: PartnerContextQueryNode,
        variables: {},
        signal: args.request.signal,
      });

      const {
        user,
        enterprise,
        access,
      } = response;


      store.dispatch(authInit({
        enterprise: {
          id: enterprise.id,
          displayName: enterprise.displayName,
          status: enterprise.status,
        },
        user: {
          id: user.id,
          email: user.email.formatted,
          displayName: user.displayName,
          avatarUrl: user.avatar?.url,
        },
        access,
      }));

      if (enterprise.status === PartnerStatus.Inactive) {
        return redirect("/onboarding");
      }
    }

    console.debug("[partnerContextLoader] Partner context is present, continuing");
    return wrapped(args);
  };
};

export default partnerContextLoader;
