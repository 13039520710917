import { css as p } from "styled-components";
const d = {
  sm: p`width: 36px; height: 36px; padding: 8px`,
  md: p`width: 40px; height: 40px; padding: 10px`,
  lg: p`width: 44px; height: 44px; padding: 10px`,
  xl: p`width: 48px; height: 48px; padding: 12px`,
  xxl: p`width: 60px; height: 60px; padding: 16px`
};
export {
  d as IconButtonCSS
};
