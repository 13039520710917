import { j as r } from "./jsx-runtime-BcAkpsdy.js";
import n from "styled-components";
import { Link as i } from "react-router-dom";
const o = n(i)`
  font-family: inherit;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  
  text-decoration: none;
  
  color: ${(e) => e.theme.palette.primary[50].contrastText};
  
  &.external {
    text-decoration: underline;
  }
  
  &.disabled {
      color: ${(e) => e.theme.palette.primary[300].hex};
    }

    &:hover:not(.disabled) {
      color: ${(e) => e.theme.palette.primary[800].hex};
    }

    &:focus:not(:hover) {
      outline: 4px solid ${(e) => e.theme.palette.primary[100].hex};
    }
`, m = ({ external: e, ...t }) => e ? /* @__PURE__ */ r.jsx(o, { ...t, className: "external", target: "_blank" }) : /* @__PURE__ */ r.jsx(o, { ...t });
export {
  m as L
};
