import React, { Suspense } from "react";
import { createBrowserRouter, LoaderFunction, redirect, RouterProvider } from "react-router-dom";
import LoginPage from "src/pages/Login";
import LogoutPage from "src/pages/Logout";
import PasswordResetPage from "src/pages/PasswordReset";
import { Layout } from "../../components/Layout";

import tokenLoader from "./tokenLoader";
import SomethingWentWrongPage from "../../pages/SomethingWentWrong";
import partnerContextLoader from "./partnerContextLoader";

const HomeRoot = React.lazy(() => import("src/pages/Home"));
const Signup = React.lazy(() => import("src/pages/Signup"));
const Onboarding = React.lazy(() => import("src/pages/Onboarding"));

const AccountRoot = React.lazy(() => import("src/pages/Account"));
const AccountProfile = React.lazy(() => import("src/pages/Account/Profile"));
const AccountSecurity = React.lazy(() => import("src/pages/Account/Security"));

const SettingsRoot = React.lazy(() => import("src/pages/Settings/Settings"));
const SettingsGeneral = React.lazy(() => import("src/pages/Settings/General"));
const SettingsTeam = React.lazy(() => import("src/pages/Settings/Team"));

const Waitlists = React.lazy(() => import("src/pages/Waitlists"));
const Waitlist = React.lazy(() => import("src/pages/Waitlist/Waitlist"));
const NewWaitlist = React.lazy(() => import("src/pages/NewWaitlist"));
const Fulfilments = React.lazy(() => import("src/pages/Waitlist/Fulfilments"));

const EditWaitlist = React.lazy(() => import("src/pages/Waitlist/EditWaitlist"));
const EditWaitlistDetails = React.lazy(() => import("src/pages/Waitlist/EditWaitlist/Details"));
const EditWaitlistSettings = React.lazy(() => import("src/pages/Waitlist/EditWaitlist/Settings"));

const TradesRoot = React.lazy(() => import("src/pages/Trades"));
const TradesHistory = React.lazy(() => import("src/pages/Trades/History"));
const TradesPayout = React.lazy(() => import("src/pages/Trades/Payout"));
export const NULL: LoaderFunction = () => {
  return null;
};

const AppRouter = () => {
  const login = (<LoginPage />);
  const logout = (<LogoutPage />);
  const layout = (<Layout />);
  const passwordReset = (<PasswordResetPage />);

  const suspended = (children: React.ReactNode, skeleton?: React.ReactNode) => (
    <Suspense fallback={skeleton}>
      {children}
    </Suspense>
  );

  const router = createBrowserRouter([
    {
      path: "login",
      element: login,
    },
    {
      path: "logout",
      element: logout,
    },
    {
      path: "signup",
      element: suspended(<Signup />),
    },
    {
      path: "onboarding",
      element: suspended(<Onboarding />),
      loader: tokenLoader(partnerContextLoader(NULL)),
    },
    {
      path: "password-reset",
      element: passwordReset,
    },
    {
      path: "/waitlists/new",
      errorElement: (<SomethingWentWrongPage />),
      loader: tokenLoader(partnerContextLoader(NULL)),
      element: suspended(<NewWaitlist />),
    },
    {
      element: layout,
      errorElement: (<SomethingWentWrongPage />),
      loader: tokenLoader(partnerContextLoader(NULL)),
      children: [ {
        path: "/",
        element: suspended(<HomeRoot/>),
      }, {
        path: "account",
        element: suspended(<AccountRoot />),
        children: [ {
          index: true,
          loader: () => redirect("/account/profile"),
          handle: {
            crumb: () => {
              return { selectedIndex: 0 };
            },
          },
        }, {
          path: "profile",
          element: suspended(<AccountProfile />),
          handle: {
            crumb: () => {
              return { selectedIndex: 0 };
            },
          },
        },
        {
          path: "security",
          element: suspended(<AccountSecurity />),
          handle: {
            crumb: () => {
              return { selectedIndex: 1 };
            },
          },
        } ],
      }, {
        path: "settings",
        element: suspended(<SettingsRoot />),
        children: [ {
          index: true,
          loader: () => redirect("/settings/general"),
          handle: {
            crumb: () => {
              return { selectedIndex: 0 };
            },
          },
        }, {
          path: "general",
          element: suspended(<SettingsGeneral />),
          handle: {
            crumb: () => {
              return { selectedIndex: 0 };
            },
          },
        },
        {
          path: "team",
          element: suspended(<SettingsTeam />),
          handle: {
            crumb: () => {
              return { selectedIndex: 1 };
            },
          },
        } ],
      }, {
        path: "/waitlists",
        element: suspended(<Waitlists/>),
      }, {
        path: "/waitlists/:waitlistID",
        element: suspended(<Waitlist/>),
      }, {
        path: "/waitlists/:waitlistID/fulfilments",
        element: suspended(<Fulfilments/>),
      },
      {
        path: "/waitlists/:waitlistID/edit",
        element: suspended(<EditWaitlist />),
        children: [
          {
            index: true,
            loader: () => redirect("details"),
            handle: {
              crumb: () => {
                return { selectedIndex: 0 };
              },
            },
          }, {
            path: "details",
            element: suspended(<EditWaitlistDetails />),
            handle: {
              crumb: () => {
                return { selectedIndex: 0 };
              },
            },
          },
          {
            path: "settings",
            element: suspended(<EditWaitlistSettings />),
            handle: {
              crumb: () => {
                return { selectedIndex: 1 };
              },
            },
          }
        ]
      }, {
        path: "trades",
        element: suspended(<TradesRoot />),
        children: [ {
          index: true,
          loader: () => redirect("/trades/history"),
          handle: {
            crumb: () => {
              return { selectedIndex: 0 };
            },
          },
        }, {
          path: "history",
          element: suspended(<TradesHistory />),
          handle: {
            crumb: () => {
              return { selectedIndex: 0 };
            },
          },
        },
        {
          path: "payout",
          element: suspended(<TradesPayout />),
          handle: {
            crumb: () => {
              return { selectedIndex: 1 };
            },
          },
        } ],
      },
      ],
    },
  ]);

  return (<RouterProvider router={router} />);
};

export default AppRouter;
