import { LoaderFunction, redirect } from "react-router-dom";
import { WebAuth } from "auth0-js";
import { store } from "src/redux/store";
import { authLogin } from "src/redux/auth";

export const tokenLoader = (wrapped: LoaderFunction): LoaderFunction => {
  return async (args) => {
    const {
      auth,
      environment,
    } = store.getState();

    console.debug("[tokenLoader] Checking if authorization token is present");

    if (!auth.token) {
      console.debug("[tokenLoader] Authorization token is not present, attempting to reacquire");

      const {
        config,
        redirectUri,
        scope,
      } = environment;

      if (!config) {
        throw Error("[tokenLoader] Environment configuration is not yet available");
      }

      const auth0 = new WebAuth({
        domain: config.auth.domain,
        clientID: config.auth.clientID,
        audience: config.auth.audience,
        redirectUri,
        scope,
      });

      const loggedIn = await new Promise((resolve) => {
        auth0.checkSession({
          responseType: "token",
        }, (_, x) => {
          if (x?.accessToken) {
            store.dispatch(authLogin(x.accessToken));
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });

      if (!loggedIn) {
        console.debug("[tokenLoader] Authorization token is not present, redirecting to login");
        return redirect("/login");
      }
    }

    console.debug("[tokenLoader] Authorization token is present, continuing");
    return wrapped(args);
  };
};

export default tokenLoader;
