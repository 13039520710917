import { j as y } from "./jsx-runtime-BcAkpsdy.js";
import { forwardRef as b } from "react";
import $ from "clsx";
import g from "styled-components";
import { ButtonCSS as r } from "./components/Button/styles.es.js";
const u = g.button`
  border-radius: 120px;
  
  font-family: inherit;
  
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
    
  &.size-sm {
    ${r.sm}
  }

  &.size-md {
    ${r.md}
  }

  &.size-lg {
    ${r.lg}
  }

  &.size-xl {
    ${r.xl}
  }

  &.size-xxl {
    ${r.xxl}
  }
  
  &:not(.disabled) {
    cursor: pointer;
  }
  
  &.variant-primary {
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    border-style: none;
    border-width: 0;
    
    &.color-primary, &.color-secondary, &.color-gray {
      color: ${(e) => e.theme.palette.primary[600].contrastText};
      background-color: ${(e) => e.theme.palette.primary[600].hex};

      &:hover {
        color: ${(e) => e.theme.palette.primary[700].contrastText};
        background-color: ${(e) => e.theme.palette.primary[700].hex};
      }

      &:focus {
        outline: 4px solid #F4EBFF;
      }

      &.disabled {
        color: ${(e) => e.theme.palette.primary[600].contrastText};
        background-color: ${(e) => e.theme.palette.primary[200].hex};
      }
    }
  }

  &.variant-secondary {
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    border-style: solid;
    border-width: 1px;
    
    &.color-primary, &.color-secondary {
      color: ${(e) => e.theme.palette.primary[50].contrastText};
      background-color: ${(e) => e.theme.palette.primary[50].hex};
      border-color: ${(e) => e.theme.palette.primary[200].hex};
  
      &.disabled {
        color: ${(e) => e.theme.palette.primary[300].hex};
        background-color: ${(e) => e.theme.palette.primary[25].hex};
        border-color: ${(e) => e.theme.palette.primary[25].hex};
      }
  
      &:hover:not(.disabled) {
        color: ${(e) => e.theme.palette.primary[800].hex};
        background-color: ${(e) => e.theme.palette.primary[100].hex};
      }
  
      &:focus {
        outline: 4px solid ${(e) => e.theme.palette.primary[100].hex};
        box-shadow: 0 0 0 4px ${(e) => e.theme.palette.primary[100].hex}, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      }
    }
    
    &.color-gray {
      color: ${(e) => e.theme.palette.grays[700].hex};
      background-color: ${(e) => e.theme.palette.white.hex};
      border-color: ${(e) => e.theme.palette.grays[300].hex};
      
      &.disabled {
        color: ${(e) => e.theme.palette.grays[300].hex};
        background-color: ${(e) => e.theme.palette.white.hex};
        border-color: ${(e) => e.theme.palette.grays[200].hex};
      }
      
      &:hover:not(.disabled) {
        background-color: ${(e) => e.theme.palette.grays[50].hex};
      }
  
      &:focus {
        outline: 4px solid ${(e) => e.theme.palette.grays[100].hex};
        box-shadow: 0 0 0 4px ${(e) => e.theme.palette.grays[100].hex}, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      }
    }
  }

  &.variant-tertiary {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 1px;

    &.color-primary, &.color-secondary {
      color: ${(e) => e.theme.palette.primary[700].hex};
      background-color: transparent;
      border-color: transparent;

      &.disabled {
        color: ${(e) => e.theme.palette.grays[300].hex};
      }

      &:hover:not(.disabled) {
        color: ${(e) => e.theme.palette.primary[800].hex};
        background-color: ${(e) => e.theme.palette.primary[50].hex};
      }

      &:focus {
        outline: none;
      }
    }
    
    &.color-gray {
      color: ${(e) => e.theme.palette.grays[700].hex};
      background-color: transparent;
      border-color: transparent;
      
      &.disabled {
        color: ${(e) => e.theme.palette.grays[300].hex};
      }
      
      &:hover:not(.disabled) {
        background-color: ${(e) => e.theme.palette.grays[50].hex};
      }
  
      &:focus {
        outline: none;
      }
    }
  }

  &.variant-link {
    height: unset;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    border-style: none;
    border-width: 0;
    
    &.color-primary, &.color-secondary {
      color: ${(e) => e.theme.palette.primary[50].contrastText};
  
      &.disabled {
        color: ${(e) => e.theme.palette.grays[300].hex};
      }
  
      &:hover:not(.disabled) {
        color: ${(e) => e.theme.palette.primary[800].hex};
      }
  
      &:focus {
        outline: 4px solid ${(e) => e.theme.palette.primary[100].hex};
        box-shadow: 0 0 0 4px ${(e) => e.theme.palette.primary[100].hex}, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      }
    }
    
    &.color-gray {
      color: ${(e) => e.theme.palette.grays[600].hex};
      
      &.disabled {
        color: ${(e) => e.theme.palette.grays[300].hex};
      }
      
      &:hover:not(.disabled) {
        color: ${(e) => e.theme.palette.grays[700].hex};
      }
  
      &:focus {
        outline: 4px solid ${(e) => e.theme.palette.grays[100].hex};
        box-shadow: 0 0 0 4px ${(e) => e.theme.palette.grays[100].hex}, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      }
    }
  }
`, f = b((e, t) => {
  const {
    as: a = "button",
    variant: l = "primary",
    color: p = "primary",
    style: s = "primary",
    size: i = "sm",
    type: h = "button",
    disabled: o = !1,
    onClick: c,
    children: d,
    className: m,
    ...n
  } = e, x = $(
    `variant-${l}`,
    `color-${p}`,
    `style-${s}`,
    `size-${i}`,
    {
      disabled: o
    },
    m
  );
  return /* @__PURE__ */ y.jsx(
    u,
    {
      ...n,
      ref: t,
      as: a,
      type: h,
      disabled: o,
      className: x,
      onClick: c,
      children: d
    }
  );
});
f.displayName = "Button";
export {
  f as B
};
