import { graphql } from "src/api/gql";

export const PartnerContextQueryNode = graphql(`
    query PartnerContext {
        user {
            id
            displayName
            email {
                formatted(format: simple)
            }
            avatar {
                url
            }
        }
        enterprise: partner {
            id
            displayName
            status
        }
        access
    }
`);
