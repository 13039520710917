import styled from "styled-components";
import { SomethingWentWrongWidget } from "@spotswap/shared";

const Root = styled.div`
  background-color: ${(props) => props.theme.palette.white.hex};

  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: stretch;

  > main {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: stretch;
  }
`;

const SomethingWentWrongPage = () => (
  <Root>
    <main>
      <SomethingWentWrongWidget />
    </main>
  </Root>
);

export default SomethingWentWrongPage;
