import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";

export interface ImportWaitlistMembersState {
  open: boolean;
  id?: string;
}

const initialState: ImportWaitlistMembersState = {
  open: false,
};
export const importWaitlistMembers = createSlice({
  name: "importWaitlistMembers",
  initialState,
  reducers: {
    openImportWaitlistMembers(state: Draft<ImportWaitlistMembersState>, action: PayloadAction<string>) {
      state.open = true;
      state.id = action.payload;
    },
    closeImportWaitlistMembers(state: Draft<ImportWaitlistMembersState>) {
      state.open = false;
      state.id = undefined;
    },
  },
});

export const {
  openImportWaitlistMembers,
  closeImportWaitlistMembers,
} = importWaitlistMembers.actions;

export default importWaitlistMembers.reducer;
