import { createSlice as l } from "@reduxjs/toolkit";
const i = {
  xxs: "320px",
  xs: "375px",
  sm: "425px",
  md: "768px",
  lg: "1024px",
  xl: "1140px",
  xxl: "2560px"
}, w = {
  mobile: `(max-width: ${i.sm})`,
  tablet: `(min-width: ${i.sm}) and (max-width: ${i.md})`,
  desktop: `(min-width: ${i.md})`,
  printer: "print"
}, E = (F, t, s, o = 300) => F < o ? s : t, d = (F, t, s, o, r = 300) => ({
  hex: t,
  contrastText: E(F, s, o, r)
}), a = (F, t, s, o = 300) => Object.keys(F).map((r) => parseInt(r)).reduce((r, c) => ({
  ...r,
  [c]: d(c, F[c], s, t, o)
}), {}), e = {
  white: "#FFFFFF",
  black: "#000000"
}, n = {
  25: "#FCFCFD",
  50: "#F9FAFB",
  100: "#F2F4F7",
  200: "#EAECF0",
  300: "#D0D5DD",
  400: "#98A2B3",
  500: "#667085",
  600: "#475467",
  700: "#344054",
  800: "#1D2939",
  900: "#101828",
  950: "#0C111D"
}, h = {
  25: "#FFFEFC",
  50: "#FFFBF6",
  100: "#FDEFDD",
  200: "#FBDBB5",
  300: "#F9C280",
  400: "#F5A442",
  500: "#F69D30",
  600: "#F28500",
  700: "#B46300",
  800: "#794300",
  900: "#472700",
  950: "#201200"
}, D = {
  25: "#FFFBFA",
  50: "#FEF3F2",
  100: "#FEE4E2",
  200: "#FECDCA",
  300: "#FDA29B",
  400: "#F97066",
  500: "#F04438",
  600: "#D92D20",
  700: "#B42318",
  800: "#912018",
  900: "#7A271A",
  950: "#55160C"
}, p = {
  25: "#FFFCF5",
  50: "#FFFAEB",
  100: "#FEF0C7",
  200: "#FEDF89",
  300: "#FEC84B",
  400: "#FDB022",
  500: "#F79009",
  600: "#DC6803",
  700: "#B54708",
  800: "#93370D",
  900: "#7A2E0E",
  950: "#4E1D09"
}, m = {
  25: "#F6FEF9",
  50: "#ECFDF3",
  100: "#DCFAE6",
  200: "#A9EFC5",
  300: "#75E0A7",
  400: "#47CD89",
  500: "#17B26A",
  600: "#079455",
  700: "#067647",
  800: "#085D3A",
  900: "#074D31",
  950: "#053321"
}, C = {
  white: {
    hex: e.white,
    contrastText: e.black
  },
  black: {
    hex: e.black,
    contrastText: e.white
  },
  grays: a(n, n[500], e.white),
  error: a(D, D[700], e.white),
  warning: a(p, p[700], e.white),
  success: a(m, m[700], e.white),
  primary: a(h, h[700], e.white)
}, x = {
  palette: C,
  colors: {
    primary: "#F28500",
    secondary: "#000435",
    tertiary: "#242B34",
    foreground: "#F28500",
    background: "#FAFAFA",
    separator: "#F0F0F0"
  },
  sizes: {
    desktop: {
      header: "64px"
    }
  }
}, A = l({
  name: "theme",
  initialState: x,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setTheme(F, t) {
      console.debug("[redux:theme] Changing application theme [setTheme]", t.payload), {
        ...x,
        ...t.payload
      };
    }
  }
}), {
  setTheme: g
} = A.actions;
export {
  w as D,
  i as M,
  x as i,
  g as s,
  A as t
};
