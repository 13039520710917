import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { NotificationProps } from "@spotswap/shared/Notification";

type StateNotificationProps = {
  id: string;
} & NotificationProps;
export interface NotificationsState {
  notifications: StateNotificationProps[];
}

const initialState: NotificationsState = {
  notifications: [],
};
export const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    createNotification(state: Draft<NotificationsState>, action: PayloadAction<NotificationProps>) {
      state.notifications.push({ ...action.payload, id: Math.random().toString(36) });
    },
    removeNotification(state: Draft<NotificationsState>, action: PayloadAction<string>) {
      state.notifications = state.notifications.filter((notification) => notification.id !== action.payload);
    },
  },
});

export const {
  createNotification,
  removeNotification,
} = notifications.actions;

export default notifications.reducer;
